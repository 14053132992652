<template lang="pug">
  b-card.manager-machines-analytics-show.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(:tabs='tabs')
    b-card-body.mt-3.mt-md-4

      b-row(cols=1)
        b-col.mb-5
          wc-forms-analytics-filter(
            :period-default='filterPeriodDefault'
            :form-api-base='apiBase'
            form-api-model='analytics'
            :form-api-params='apiParams'
            :data.sync='filter')

      b-row(cols=1)
        b-col.mb-5
          panel-machine(
            namespace='manager/machines/analytics'
            :data='filter'
            height='80px'
            group='manager-machines-analytics'
            :connect='true')

      b-row(cols=1 cols-md=1 cols-lg=2 cols-xl=2)
        b-col.mb-5
          panel-batteries(
            namespace='manager/machines/analytics'
            :data='filter'
            group='manager-machines-analytics'
            :connect='true')

        b-col.mb-5(v-if='hasBusData')
          panel-operating-hours(
            namespace='manager/machines/analytics'
            :data='filter'
            group='manager-machines-analytics'
            :connect='true')

        b-col.mb-5(v-if='hasBusData')
          panel-tilt(
            namespace='manager/machines/analytics'
            :data='filter'
            group='manager-machines-analytics'
            :connect='true')

        b-col.mb-5
          panel-temperatures(
            namespace='manager/machines/analytics'
            :data='filter'
            group='manager-machines-analytics'
            :connect='true')

        b-col.mb-5(v-if='hasBusData')
          panel-motors-voltage(
            namespace='manager/machines/analytics'
            :data='filter'
            group='manager-machines-analytics'
            :connect='true')

        b-col.mb-5(v-if='hasBusData')
          panel-motors-current(
            namespace='manager/machines/analytics'
            :data='filter'
            group='manager-machines-analytics'
            :connect='true')

      b-row(
        v-if='!hasBusData'
        cols=1)
        b-col.mb-5
          panel-motors-percentage(
            namespace='manager/machines/analytics'
            :data='filter'
            group='manager-machines-analytics'
            :connect='true')

      b-row(cols=1)
        b-col
          panel-map(
            namespace='manager/machines/analytics'
            :data='filter')
</template>

<script>
import Tabs from '@views/container/manager/machines/shared/tabs'
import MachineAnalytics from '@common/machines/analytics'
import apiAnalytics from '@services/api/manager/machines/analytics'
import PanelBatteries from '@components/layouts/shared/machines/analytics/PanelBatteries'
import PanelMachine from '@components/layouts/shared/machines/analytics/PanelMachine'
import PanelMap from '@components/layouts/shared/machines/analytics/PanelMap'
import PanelMotorsPercentage from '@components/layouts/shared/machines/analytics/PanelMotorsPercentage'
import PanelMotorsVoltage from '@components/layouts/shared/machines/analytics/PanelMotorsVoltage'
import PanelMotorsCurrent from '@components/layouts/shared/machines/analytics/PanelMotorsCurrent'
import PanelTemperatures from '@components/layouts/shared/machines/analytics/PanelTemperatures'
import PanelOperatingHours from '@components/layouts/shared/machines/analytics/PanelOperatingHours'
import PanelTilt from '@components/layouts/shared/machines/analytics/PanelTilt'

export default {
  name: 'manager-machines-analytics-show',
  mixins: [Tabs, MachineAnalytics],
  props: {
    filterPeriodDefault: {
      type: String,
      default: 'last_12_hours',
    },
  },
  components: {
    PanelBatteries,
    PanelMachine,
    PanelMap,
    PanelMotorsPercentage,
    PanelMotorsVoltage,
    PanelMotorsCurrent,
    PanelTemperatures,
    PanelOperatingHours,
    PanelTilt,
  },
  computed: {
    hasBusData() {
      return this.$getDeep(this.$parent.$parent.$refs, 'machinesPanel.panel.data.attributes.bus')
    },
  },
  methods: {},
  data() {
    return {
      apiBase: apiAnalytics,
      filter: {
        period: this.filterPeriodDefault,
        from: null,
        to: null,
      },
    }
  },
}
</script>
